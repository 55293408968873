import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import i18n from '@/plugins/i18n';

import { store } from '@/store';

import { numberWithCommas } from '../../../wettbewerbsvergleich/src/utils/helpers';

import {
  FESTGELD_TABLE_HEADERS_ORDER,
  INTEREST_CALCULATION,
  INTEREST_CREDIT_TAGESGELD,
  TAGESGELD_TABLE_HEADERS_ORDER,
} from '@/utils/constants';

function getOfferFieldValue(reportType, content) {
  const OFFERS = {
    8: {
      1: i18n.messages[i18n.locale].common.dataIterator.simpleTable
        .privatperson,
      2: i18n.messages[i18n.locale].common.dataIterator.simpleTable
        .businessClient,
    },
    1: {
      0: i18n.messages[i18n.locale].common.dataIterator.simpleTable.allOffers,
      1: i18n.messages[i18n.locale].common.dataIterator.simpleTable
        .onlyNewCustomersOffers,
      2: i18n.messages[i18n.locale].common.dataIterator.simpleTable
        .existingCustomersOffers,
    },
  };
  const offerField =
    +reportType === 1
      ? content.config.banken_kundenkreis
      : content.config.banken_zielgruppe;
  return OFFERS[reportType][offerField];
}
function s2ab(s) {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}

Object.defineProperty(Array.prototype, 'chunk', {
  value: function (chunkSize) {
    const R = [];
    for (let i = 0; i < this.length; i += chunkSize)
      R.push(this.slice(i, i + chunkSize));
    return R;
  },
});

String.prototype.MylocaleCompare = function (right, idx) {
  idx = idx === undefined ? 0 : idx++;

  const run =
    right.length <= this.length
      ? idx < right.length - 1
      : idx < this.length - 1;

  if (!run) {
    if (this[0].localeCompare(right[0]) === 0) {
      return this.localeCompare(right);
    } else {
      return this[0].localeCompare(right[0]);
    }
  }

  if (this.localeCompare(right) !== this[0].localeCompare(right[0])) {
    const myLeft = this.slice(1, this.length);
    const myRight = right.slice(1, right.length);
    if (myLeft.localeCompare(myRight) !== myLeft[0].localeCompare(myRight[0])) {
      return myLeft.MylocaleCompare(myRight, idx);
    } else {
      if (this[0].localeCompare(right[0]) === 0) {
        return myLeft.MylocaleCompare(myRight, idx);
      } else {
        return this[0].localeCompare(right[0]);
      }
    }
  } else {
    return this.localeCompare(right);
  }
};

export const mappedInterestCredit = (
  value,
  localeObject = INTEREST_CREDIT_TAGESGELD
) => localeObject[value] || value;

export const mappedInterestCalculation = (
  value,
  localeObject = INTEREST_CALCULATION
) => localeObject[value] || value;

export const hasAccess = (to, from, next) => {
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  if (permissions && Array.isArray(to.meta.permission)) {
    const search = permissions.filter((item) =>
      to.meta.permission.includes(item)
    );
    if (search.length === 3) {
      next();
    } else {
      next({ name: 'dashboard' });
    }
  }
  if (permissions && typeof to.meta.permission === 'string') {
    if (permissions.includes(to.meta.permission)) {
      next();
    } else {
      next({ name: 'dashboard' });
    }
  }

  if (!permissions) {
    store.commit('auth/clearStore');
    store.commit('specialSubscribers/resetState');
    store.commit('param/resetState');
    store.commit('wizard/resetState');
    store.commit('reports/resetState');
    store.commit('bankingProfile/resetState');
    localStorage.clear();
    next({ name: 'login' });
  }
};

export function dateForCsv(date, currentObjForSaving) {
  const metaDate = date(currentObjForSaving?.meta?.data_time).format(
    'DD_MM_YYYY'
  );

  return metaDate ? metaDate : date(new Date()).format('DD.MM.YYYY');
}

export function reportDatePdf(date, currentObjForSaving) {
  const metaDate = date(currentObjForSaving?.meta?.data_time).format(
    'DD.MM.YYYY'
  );

  return metaDate ? metaDate : date(new Date()).format('DD.MM.YYYY');
}

export async function savePDF(
  ref,
  fileName,
  dates,
  dateVom,
  dateRangeText,
  date
) {
  ref.style.fontFamily = 'Recursive';
  const opt = {
    margin: [75, 0, 0, 0],
    filename: `${fileName}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      useCORS: true,
      imageTimeout: 0,
    },
    pagebreak: {
      mode: ['avoid-all', 'css', 'legacy'],
    },
    jsPDF: {
      unit: 'pt',
      format: [ref.scrollHeight + 75, ref.scrollWidth],
      orientation: 'landscape',
    },
  };

  let image = '';
  let fontRobotoMedium = '';
  await getDataUrl('/assets/img/fmh-logo.png').then((res) => {
    image = res.split(',')[1];
  });
  await getDataUrl('/assets/fonts/Recursive-Regular.ttf').then((res) => {
    fontRobotoMedium = res.split(',')[1];
  });
  await html2pdf()
    .from(ref)
    .set(opt)
    .toPdf()
    .get('pdf')
    .then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.addImage(image, 'PNG', 10, 10, 200, 30);
        pdf.addFileToVFS('Recursive-Regular.ttf', fontRobotoMedium);
        pdf.addFont('Recursive-Regular.ttf', 'Recursive-Regular', 'normal');
        pdf.setFont('Recursive-Regular');
        if (i === 1) {
          pdf.setTextColor('#7f7f7f');
          if (dates.length > 1) {
            pdf.text(250, 28, `Vergleich: ${dateRangeText}`);
            pdf.text(500, 28, `Es werden die Daten vom ${dates[0]} angezeigt`);
            pdf.setTextColor('#7f7f7f');
          } else {
            pdf.text(250, 28, `Daten vom: ${dateVom}`);
            pdf.setTextColor('#7f7f7f');
          }
        }
      }
    })
    .save();
}

export function exportXLS(ref, fileName, firstLine) {
  let wb = XLSX.utils.table_to_book(ref, {
    raw: true,
    origin: 2,
  });
  let ws = wb.Sheets['Sheet1'];

  XLSX.utils.sheet_add_aoa(ws, [[firstLine || '']], {
    origin: 0,
  });
  let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  saveAs(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    `${fileName}.xlsx`
  );
}
export const exportCSV = (ref, fileName) => {
  const rows = ref.querySelectorAll('table tr');
  let csv = [];
  for (const row of rows) {
    const cells = row.querySelectorAll('td, th');
    const rowText = Array.from(cells).map((cell) => cell.innerText);
    csv.push(rowText.join(';'));
  }
  const csvFile = new Blob([csv.join('\n')], {
    type: 'text/csv; charset=utf-8,',
  });
  saveAs(csvFile, `${fileName}.csv`);
};

export function headersForCSV(content, reportType) {
  const headers = getSortedHeaders(content.headers, reportType);
  const arr = [];
  headers.map((item) => {
    if (item.value === 'anbieter') {
      arr.push({
        anbieter_anbietername: {
          title:
            i18n.messages[i18n.locale].common.generalTable.headers.anbieter,
        },
      });
    }
    if (item.value === 'produkt') {
      arr.push({
        produkt_produktname: {
          title: i18n.messages[i18n.locale].common.generalTable.headers.produkt,
        },
      });
    }
    arr.push({
      [item.value]: {
        title:
          i18n.messages[i18n.locale].common.generalTable.headers[item.value],
      },
    });
  });
  if (reportType === 1) {
    arr.push({
      offer: {
        title:
          i18n.messages[i18n.locale].common.generalTable.headers.customerBase,
      },
    });
  }
  if (reportType === 8) {
    arr.push({
      offer: {
        title:
          i18n.messages[i18n.locale].common.generalTable.headers.zielgruppe,
      },
    });
  }
  const csvHeaders = arr.reduce(
    (result, object) => Object.assign(result, object),
    {}
  );
  if (csvHeaders.hasOwnProperty('anbieter')) {
    delete csvHeaders['anbieter'];
  }
  if (csvHeaders.hasOwnProperty('produkt')) {
    delete csvHeaders['produkt'];
  }
  return csvHeaders;
}

export function rowsForCSV(
  content,
  dataInterestCredit,
  date,
  interestCalculation,
  reportType
) {
  const originalRows = content.rows;
  const copy = originalRows?.slice(0);
  const result = copy?.map((row) => {
    const keysHeadersCsv = Object.keys(headersForCSV(content) || {});
    const o = { ...row };
    const bankName = [1, 2, 8].includes(+reportType)
      ? row.anbieter_anbietername
      : row.a_anbieter?.split('|')[2];

    const key = [1, 2, 8].includes(+reportType)
      ? 'anbieter_anbietername'
      : 'a_anbieter';
    if ([1, 8].includes(+reportType)) {
      o.offer = getOfferFieldValue(reportType, content);
    }

    if (bankName && bankName.charAt(bankName.length - 1) === '*') {
      o[key] = `${row[key].slice(0, row[key].length - 1)} (Business)`;
    }
    keysHeadersCsv.forEach((key) => {
      if (key === 'einlagensicherung') {
        if (
          o['einlagensicherung_0_bezeichnung'] &&
          o['einlagensicherung_1_bezeichnung']
        ) {
          o[
            key
          ] = `Erweitert, ${o['einlagensicherung_0_landname']} und ${o['einlagensicherung_1_landname']}`;
        } else {
          o[key] = `Gesetzliche, ${o['einlagensicherung_0_landname']}`;
        }
      }
      if (!o[key] && typeof o[key] !== 'boolean') {
        o[key] = '-';
      }
    });
    if ('produkt_aktionszins' in row) {
      o.produkt_aktionszinssatz =
        typeof row.produkt_aktionszins === 'boolean'
          ? row.produkt_aktionszins === true
            ? i18n.messages[i18n.locale].general['ja']
            : i18n.messages[i18n.locale].general['nein']
          : '';
    }
    if ('produkt_aktionszinssatz' in row && row.produkt_aktionszinssatz) {
      o.produkt_aktionszinssatz = `${numberWithCommas(
        o.produkt_aktionszinssatz
      )}%`;
    }
    if ('produkt_zinssatz' in row && row.produkt_zinssatz) {
      o.produkt_zinssatz = `${numberWithCommas(o.produkt_zinssatz)}%`;
    }
    if ('produkt_zinsberechnung' in row) {
      o.produkt_zinsberechnung = interestCalculation(
        row.produkt_zinsberechnung
      );
    }
    if ('produkt_zinsgutschrift' in row) {
      o.produkt_zinsgutschrift = dataInterestCredit(row.produkt_zinsgutschrift);
    }
    if ('produkt_neukundenangebot' in row) {
      o.produkt_neukundenangebot = date(row.produkt_datumZinsaenderung).format(
        'DD.MM.YYYY'
      );
    }
    if ('produkt_datumZinsaenderung' in row) {
      o.produkt_datumZinsaenderung = date(
        row.produkt_datumZinsaenderung
      ).format('DD.MM.YYYY');
    }
    if ('produkt_mindestanlage' in row && row.produkt_mindestanlage) {
      o.produkt_mindestanlage = `${o.produkt_mindestanlage.toLocaleString(
        'de-DE'
      )}€`;
    }
    if ('produkt_maximalanlage' in row && row.produkt_maximalanlage) {
      o.produkt_maximalanlage = `${o.produkt_maximalanlage.toLocaleString(
        'de-DE'
      )}€`;
    }
    if ('produkt_maximalanlageProdukt' in row) {
      if (typeof row.produkt_maximalanlageProdukt === 'number') {
        o.produkt_maximalanlageProdukt =
          row.produkt_maximalanlageProdukt === 0
            ? i18n.messages[i18n.locale].common.generalTable.unlimited
            : `${row.produkt_maximalanlageProdukt.toLocaleString('de-DE')}€`;
      } else {
        o.produkt_maximalanlageProdukt = '';
      }
    }
    if ('produkt_abBetrag' in row && row.produkt_abBetrag) {
      o.produkt_abBetrag = `${row.produkt_abBetrag.toLocaleString('de-DE')}`;
    }
    if ('produkt_anschlussZinssatz' in row && row.produkt_anschlussZinssatz) {
      o.produkt_anschlussZinssatz = `${o.produkt_anschlussZinssatz.toLocaleString(
        'de-DE'
      )}%`;
    }
    const keys = Object.keys(row).filter((key) => /\d+M_/.test(key));
    keys.forEach((key) => {
      if (!Number.isNaN(+row[key])) {
        o[key] =
          Number(numberWithCommas(row[key])) === 0
            ? ''
            : `${numberWithCommas(row[key])}%`;
      }
    });
    return o;
  });
  return result?.length ? result : [];
}

export function getDataUrl(url) {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
}

export function getType(productType) {
  if (productType === 'tagesgeld') {
    return '1';
  }
  if (productType === 'festgeld') {
    return '2';
  } else {
    return '3';
  }
}

export function groupBy(arr, property) {
  return arr?.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, []);
}

export function modifyAllReports(data, reportType, category) {
  return data.map(({ history, config, ...item }) => {
    const {
      products = [],
      anbieterAuswahl = [],
      selection_type,
      allBanks = [],
    } = config;
    if (reportType === 3) {
      return Object.assign(item, {
        allBanks: allBanks.filter((bank) => bank.includes('_')),
        products,
        anbieterAuswahl,
        selection_type,
        note: item.note ? item.note : null,
        tags: item.tags ? item.tags : [],
        category,
        config,
      });
    }
    return Object.assign(item, {
      selection_type,
      category,
      note: item.note ? item.note : null,
      tags: item.tags ? item.tags : [],
      config,
    });
  });
}

export function stateValuesBankingProfile(headers, chipsHeaders, values) {
  const stringOptions = {};

  const allHeaders = [...headers].concat(chipsHeaders);
  values.forEach((row) => {
    allHeaders.forEach((header) => {
      if (Array.isArray(row[header.value])) {
        if (typeof row[header.value][0] === 'string' && row[header.value][0]) {
          const options = (stringOptions[header.value] || [])
            .concat(row[header.value])
            .sort((a, b) => {
              if (
                (a.toLowerCase() || b.toLowerCase()) === 'deutschland' ||
                (a.toLowerCase() || b.toLowerCase()) === 'austria'
              ) {
                return -1;
              }
              return a.MylocaleCompare(b);
            });
          Object.assign(stringOptions, {
            [header.value]: [...new Set(options)],
          });
        }
      }
    });
  });

  return { stringOptions };
}

export const startTimer = (time, rout) => {
  setTimeout(() => {
    store.dispatch('auth/authCheck', {
      page: rout,
    });
  }, time);
};

export const fileName = (name) => {
  if (name) {
    return name
      .replace(/[^a-zA-ZßäüöÄÖÜ0-9,.\s]/gi, ' ')
      .replace(/^\s+|\s+$/g, '')
      .replace(/\s+/g, '_');
  }
  return 'file';
};

export const fileFirstRowText = (timestamp, date) => {
  //eslint-disable-next-line
  return  timestamp ? `Es werden die Daten vom ${date(timestamp).format('DD.MM.YYYY')} angezeigt` : `Es werden die Daten vom ${date().format('DD.MM.YYYY')} angezeigt`;
};

export const timePeriodOptions = () => {
  const years = [];
  const period = 6;
  const currentDate = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYears = new Date().getFullYear();
  const difference = currentYears - 1 - 2021;
  let defaultSelected = 2021;

  if (currentDate === 30 && currentMonth === 6) {
    defaultSelected = defaultSelected + difference;
  }
  if (currentDate !== 30 || currentMonth !== 6) {
    for (let i = 0; i < difference - 1; i += 1) {
      defaultSelected += 1;
    }
  }

  for (let i = 0; i <= period; i += 1) {
    years.push(String(currentYears - i));
  }
  return {
    defaultSelected: [String(defaultSelected)],
    years,
  };
};

export const generateChartLabel = (
  obj,
  data,
  options,
  subOptions,
  chartType,
  params
) => {
  const o = {};
  const locale = i18n.locale === 'en' ? 'en-US' : 'de-DE';
  Object.keys(obj).forEach((key, index) => {
    const zins = data
      .filter((item) => !isNaN(item?.[key]))
      .map((item) => item?.[key]);
    if (zins.length) {
      const label = [];
      const calcMin = Number(Math.min(...zins).toFixed(2));
      const calcMax = Number(Math.max(...zins).toFixed(2));
      const calcAverage = Number(
        (zins.reduce((all, a) => all + Number(a), 0) / zins.length).toFixed(2)
      );
      const values = ['rbGrafikAuswahl5', 'goldpreis'];
      let minFractionDigits = 2;
      if (chartType === 'special') {
        if (params.selectedSpecialValue === 'rbGrafikAuswahl5') {
          minFractionDigits = 0;

          if (
            params.selectedSpecialSubValue[index] === 'hp_hypothekenzins_index'
          ) {
            minFractionDigits = 2;
          }
          const value = params.selectedSpecialSubValue[index];
          label.push(
            i18n.messages[i18n.locale].chartGenerator.cartLabels[value]
          );
        }

        if (params.selectedSpecialValue === 'goldpreis') {
          minFractionDigits = 0;
          label.push(
            i18n.messages[i18n.locale].chartGenerator.specialChartOptions[
              params.selectedSpecialValue
            ]
          );
        }
        if (!values.includes(params.selectedSpecialValue)) {
          const filtered = subOptions[params.selectedSpecialValue].filter(
            (i) => {
              return params.selectedSpecialSubValue.includes(i.value);
            }
          );
          filtered.forEach((i, ind) => {
            if (
              params.selectedSpecialSubValue.includes(i.value) &&
              ind === index
            ) {
              label.push(
                i18n.messages[i18n.locale].chartGenerator
                  .specialChartSubOptions[params.selectedSpecialValue][i.value]
              );
            }
          });
        }
      }
      if (chartType === 'base' && obj[key].value) {
        const search = options.find((item) => item.value === obj[key].value);
        label.push(
          i18n.messages[i18n.locale].chartGenerator.interestOptions[
            search.value
          ]
        );
      }
      if (chartType === 'base' && obj[key].subValue) {
        const search = subOptions[obj[key].value].find(
          (item) => item.value === obj[key].subValue
        );
        const str = `${label[0]} - ${
          i18n.messages[i18n.locale].chartGenerator.subInterestOptions[
            obj[key].value
          ][search.value]
        }`;
        label.shift();
        label.push(str);
      }
      let min = calcMin.toLocaleString(locale, {
        minimumFractionDigits: minFractionDigits,
      });
      let max = calcMax.toLocaleString(locale, {
        minimumFractionDigits: minFractionDigits,
      });
      let average = calcAverage.toLocaleString(locale, {
        minimumFractionDigits: minFractionDigits,
      });

      if (minFractionDigits === 0 && min.includes(',')) {
        const parts = min.split(',').filter((i) => i);
        min = parts[0];
      }
      if (minFractionDigits === 0 && max.includes(',')) {
        const parts = max.split(',').filter((i) => i);
        max = parts[0];
      }
      if (minFractionDigits === 0 && average.includes(',')) {
        const parts = average.split(',').filter((i) => i);
        average = parts[0];
      }
      label.push(`Min: ${min}`);
      label.push(`Max: ${max}`);
      const averageText = i18n.locale === 'en' ? 'Mean value' : 'Durchschnitt';
      label.push(`${averageText}: ${average}`);
      Object.assign(o, { [key]: label.join('    ') });
    } else {
      Object.assign(o, { [key]: '' });
    }
  });
  return o;
};

export const modifyNumber = (value) => {
  if (!value && +value !== 0) {
    return 0;
  }
  if (String(value).length >= 5) {
    return Number(value).toLocaleString('de-DE', {
      minimumFractionDigits: 2,
    });
  }
  return Number(Number(value)).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
  });
};

export const getSortedHeaders = (headers, reportType) => {
  const oldAndNewHeadersMaxProdukt = [];
  const restHeaders = [];
  const allHeaders = [];
  const orderConfig = [1, 8].includes(reportType)
    ? TAGESGELD_TABLE_HEADERS_ORDER
    : FESTGELD_TABLE_HEADERS_ORDER;
  [...(headers || [])].forEach((header) => {
    if (
      header.value === 'produkt_maximalanlage' ||
      header.value === 'produkt_maximalanlageProdukt'
    ) {
      oldAndNewHeadersMaxProdukt.push(header);
    } else {
      restHeaders.push(header);
    }
  });
  if (oldAndNewHeadersMaxProdukt.length === 1) {
    allHeaders.push(oldAndNewHeadersMaxProdukt[0]);
  }
  if (oldAndNewHeadersMaxProdukt.length === 2) {
    const search = oldAndNewHeadersMaxProdukt.find(
      (i) => i.value === 'produkt_maximalanlageProdukt'
    );
    allHeaders.push(search);
  }
  restHeaders.forEach((header) => {
    allHeaders.push(header);
  });

  return allHeaders
    .map((i) =>
      ['produkt_anschlussZinssatz', 'einlagensicherung'].includes(i?.value)
        ? { ...i, sortable: false }
        : i
    )
    .sort((a, b) => {
      if (orderConfig[a?.value] === orderConfig[b?.value]) {
        return 0;
      }
      return orderConfig[a?.value] < orderConfig[b?.value] ? -1 : 1;
    });
};

export const generateHeadersAndRows = (obj, isCompare) => {
  const rows = [];
  const allHeaders = [...obj.headers];
  if (obj.report_type === 1) {
    allHeaders.push({
      value: 'customerBase',
      text: i18n.messages[i18n.locale].common.generalTable.headers.customerBase,
    });
  }
  if (obj.report_type === 8) {
    allHeaders.push({
      value: 'zielgruppe',
      text: i18n.messages[i18n.locale].common.generalTable.headers.zielgruppe,
    });
  }
  obj.rows.forEach((row) => {
    const o = { ...row };
    if (obj.report_type === 1) {
      o.customerBase = getOfferFieldValue(obj.report_type, obj);
    }
    if (obj.report_type === 8) {
      o.zielgruppe = getOfferFieldValue(obj.report_type, obj);
    }

    if (
      o.anbieter_anbietername.charAt(o.anbieter_anbietername.length - 1) === '*'
    ) {
      o.anbieter_anbietername = `${o.anbieter_anbietername.slice(
        0,
        o.anbieter_anbietername.length - 1
      )} (Business)`;
    }
    if (!isCompare) {
      if (o.hasOwnProperty('produkt_zinssatzVeraenderung')) {
        o.produkt_zinssatzVeraenderung = modifyNumber(
          o.produkt_zinssatzVeraenderung
        );
      }
      if (o.hasOwnProperty('produkt_aktionszinssatzVeraenderung')) {
        o.produkt_aktionszinssatzVeraenderung = modifyNumber(
          o.produkt_aktionszinssatzVeraenderung
        );
      }
    }
    if (isCompare) {
      if (o.hasOwnProperty('produkt_zinssatz_difference')) {
        o.produkt_zinssatz_difference = modifyNumber(
          o.produkt_zinssatz_difference
        );
        delete o.produkt_zinssatzVeraenderung;
      }
    }
    rows.push(o);
  });

  if (isCompare) {
    if (rows.some((i) => i.hasOwnProperty('produkt_zinssatz_difference'))) {
      allHeaders.push({
        text: i18n.messages[i18n.locale].common.generalTable.headers
          .produkt_zinssatz_difference,
        value: 'produkt_zinssatz_difference',
      });
    }
  }
  if (!isCompare) {
    if (rows.some((i) => i.hasOwnProperty('produkt_zinssatzVeraenderung'))) {
      allHeaders.push({
        text: i18n.messages[i18n.locale].common.generalTable.headers
          .produkt_zinssatzVeraenderung,
        value: 'produkt_zinssatzVeraenderung',
      });
    }
    if (
      rows.some((i) => i.hasOwnProperty('produkt_aktionszinssatzVeraenderung'))
    ) {
      allHeaders.push({
        text: i18n.messages[i18n.locale].common.generalTable.headers
          .produkt_aktionszinssatzVeraenderung,
        value: 'produkt_aktionszinssatzVeraenderung',
      });
    }
  }

  const translated = allHeaders.map((header) => {
    return {
      ...header,
      text: i18n.messages[i18n.locale].common.generalTable.headers[
        header.value
      ],
    };
  });
  return {
    rows,
    headers: getSortedHeaders(translated, 1),
  };
};

export const generateMonthFields = (arr) => {
  const array = [];
  if ((arr || []).length) {
    const subArr = [];
    arr.forEach((row) => {
      Object.keys(row).forEach((k) => {
        if (!subArr.includes(k)) {
          subArr.push(k);
        }
      });
    });
    const keys = subArr.filter((key) => /^\d.*M\_/.test(key));
    array.push(...keys);
  }
  return array;
};

export const generateRowsAndHeadersFestgeld = (rows, headers, isCompare) => {
  const fields = generateMonthFields(rows);
  const allHeaders = [...headers];
  rows.forEach((row) => {
    const o = { ...row };
    if (!isCompare) {
      Object.keys(o).forEach((key) => {
        if (fields.includes(key) && key.includes('Veraenderung')) {
          if (!allHeaders.map((i) => i.value).includes(key)) {
            allHeaders.push({
              text: i18n.messages[i18n.locale].common.generalTable.headers
                .produkt_zinssatzVeraenderung,
              value: key,
            });
          }
        }
      });
    }
    if (isCompare) {
      Object.keys(o).forEach((key) => {
        if (fields.includes(key) && key.includes('difference')) {
          if (!allHeaders.map((i) => i.value).includes(key)) {
            allHeaders.push({
              text: i18n.messages[i18n.locale].common.generalTable.headers
                .produkt_zinssatzVeraenderung,
              value: key,
            });
          }
        }
      });
    }
  });

  const translated = allHeaders.map((header) => {
    return {
      ...header,
      text: i18n.messages[i18n.locale].common.generalTable.headers[
        header.value
      ],
    };
  });
  return {
    headers: getSortedHeaders(translated, 2),
    rows: rows,
  };
};
